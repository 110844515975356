import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Box, { BoxTitle, BoxParagraph} from '../elements/box';
import Card, { CardContent, CardImage } from '../elements/card';

const Therapie_familiale = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="Les thérapies familiales" />
      <BoxParagraph>
        <p>Certains troubles psychiatriques ou certaines difficultés psychologiques ne peuvent être traités efficacement en 
          s’adressant à la seule personne qui en souffre. Car de tels troubles retentissent sur l’entourage familial, qui a 
          souvent un rôle important dans leur déterminisme même. Il est donc parfois nécessaire de prendre en charge le couple 
          ou toute la famille pour dénouer ces conflits. C’est la fonction des thérapies familiales.</p>
        <p>Nous naissons et nous grandissons dans une famille où s’instaurent nos premiers modes de relation, essentiellement 
          avec notre père et notre mère. Et là, se nouent des conflits psychiques qui seront à la base de tous nos troubles, 
          que nous projetterons ensuite pendant toute la vie sur nos interlocuteurs. Non seulement sur cette famille dont nous 
          ne sommes jamais détachés mais aussi sur notre conjoint.</p>
        <p>Les psychothérapies familiales s’adressent en général à des familles dont un enfant présente des troubles graves, 
          psychotiques ou gravement névrotiques, éventuellement des troubles du comportement ou des <a 
          href="http://www.doctissimo.fr/html/nutrition/mag_2001/mag0316/nu_3800_trouble_aliment_niv2.htm">
          troubles du comportement alimentaire sérieux</a>.</p>
        <p>Elles reposent sur le principe selon lequel un enfant perturbé fait partie d’une famille troublée elle-même dans son 
          ensemble. Il est en quelque sorte le symptôme visible de cette famille malade.</p>
        <p>On ne pourra donc le guérir sans aborder tous les aspects des interactions qui règlent le fonctionnement complexe 
          du groupe familial. Il faut garder à l’esprit que tout groupe exige pour subsister une certaine stabilité, et que 
          toucher à l’un de ses membres peut déséquilibrer les autres.</p>
        <p>La technique utilisée est issue des <b>thérapies systémiques</b>, nées aux USA, sont basées sur la théorie dite des "systèmes". Elles considèrent la famille 
          comme un système, et cherchent à en rétablir l’équilibre en s’appuyant sur les théories de la communication.</p>
      </BoxParagraph>
    </Box>
    <Box>
      <div className="columns">
        <div className="column is-4">
          <Card height="">
            <CardImage src="illustration7" alt="illustration"/>
          </Card>
        </div>
        <div className="column">
          <BoxParagraph>
            <p><b>Qui doit participer ?</b></p>
            <p>Les thérapeutes ont besoin de rencontrer la totalité des membres de la famille, parents, grands-parents, frères et 
              soeurs du patient. Au moins lors des premières séances. Ensuite, les séances réuniront les intervenants essentiels du 
              groupe familial.</p>
            <p><b>Combien de temps dure une séance ?</b></p>
            <p>Les séances durent une heure et demi. Elles ont lieu une ou deux fois par mois.</p>
            <p><b>Comment se déroule une séance ?</b></p>
            <p>Les thérapies familiales ont recours aux réunions classiques, avec prise de parole des membres présents. Le 
              thérapeute, parfois ils sont deux, dirige les échanges. Il utilise des « objets flottants », instruments d’ouverture 
              et de compréhension du système familial pour aider les membres de la famille à changer de perspective et activer des 
              leviers de changement. Un temps de recadrage en fin de séance peut permettre de structurer le sytème et la prescription 
              de tâches est une possibilité.</p>
          </BoxParagraph>
        </div>
      </div>
    </Box>
    <div className="columns">
      <div className="column is-half is-offset-one-quarter">
        <Link to='/la_systemie'>
          <Card>
            <CardContent>
              <BoxParagraph>
                <p>Pour en découvrir plus sur la <b>Systémie</b>.</p>
              </BoxParagraph>
            </CardContent>
            <CardImage src="systemie" alt="La Systémie" hoverable="true"/>
          </Card>
        </Link>
      </div>    
    </div>
  </Layout>
)

export default Therapie_familiale;